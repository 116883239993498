import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_list, { padding: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_separator),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-home" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Home ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _createVNode(_component_q_separator),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/meus-cursos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-atom" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Meus Cursos ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/minhas-duvidas"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-question-circle" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Minhas Dúvidas ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/minhas-avaliacoes"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-tasks" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Área Avaliação ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/area-desempenho"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-chart-line" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Área Desempenho ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/quadro-horarios"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-calendar" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Quadro de Horários "),
              _createVNode(_component_q_badge, {
                color: "green",
                align: "middle",
                style: {"width":"45px","font-weight":"bold"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" NOVO ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/minhas-redacoes"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-file-signature" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Área Redação ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/minhas-postagens"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-comment" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Postagens ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ])
    ]),
    _: 1
  }))
}