import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_list, { padding: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_separator),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-home" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Home ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _createVNode(_component_q_separator),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/users"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-users-cog" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Usuários ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/alunos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-user-graduate" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Alunos ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/divisoes"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-map-marked-alt" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Divisões ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/disciplinas"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-book" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Disciplinas ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/cursos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-atom" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Cursos ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/planos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-file-invoice-dollar" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Modalidades ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/turmas"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-chalkboard-teacher" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Turmas ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/area-postagem"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-comment" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Postagens ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/plantao-duvidas"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-comments" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Plantão ao Vivo ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/duvidas"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-question-circle" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Dúvidas ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/regimento-interno"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-sticky-note" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Regimento Interno ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/explorar-cursos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-search" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Explorar Cursos ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/area-avaliacao"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-tasks" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Área Avaliação ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/area-redacao"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-file-signature" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Área Redação ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/diario-classe"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fa fa-user-check" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Diário de Classe ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        tag: "a",
        to: "/quadro-de-horarios-admin"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fa fa-calendar" })
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(" Quadro de Horários "),
              _createVNode(_component_q_badge, {
                color: "green",
                align: "middle",
                style: {"width":"45px","font-weight":"bold"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" NOVO ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_ripple]
      ])
    ]),
    _: 1
  }))
}