
import { computed, defineComponent, onMounted, ref } from "vue";
import store from "@/store";
import router from "@/router";
import User from "@/interfaces/User";
import ContaService from "@/services/conta/ContaService";
import AdministradorMenu from "@/components/layout/menus/AdministradorMenu.vue";
import ProfessorMenu from "@/components/layout/menus/ProfessorMenu.vue";
import AlunoMenu from "@/components/layout/menus/AlunoMenu.vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useRoute } from "vue-router";
import DivisaoService from "@/services/auth/DivisaoService";
import Divisao from "@/interfaces/Divisao";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  components: {
    AdministradorMenu,
    ProfessorMenu,
    AlunoMenu,
  },
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const loading = ref<boolean>(false);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const user = ref<User>();
    const divisao = ref<Divisao>();

    const getUser = (): void => {
      loading.value = true;
      ContaService.get()
        .then((res) => {
          user.value = res.data;
          if (!user.value?.status) {
            logout();
            showNotify({
              type: "negative",
              message: "Usuário está bloqueado",
            });
          }
          getDivisao();
          controleAcesso();
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("token");
          showNotify({
            type: "negative",
            message: "Erro ao buscar dados",
          });
          window.location.reload();
        })
        .finally(() => (loading.value = false));
    };

    const controleAcesso = (): void => {
      loading.value = true;
      const perfis = Array.isArray(route.meta.access) ? route.meta.access : [];
      if (!perfis.includes(user.value?.perfil.nome)) router.push("/");
    };

    const getDivisao = (): void => {
      if (user.value) {
        if (
          user.value.perfil.nome != "administrador" &&
          !store.getters.divisaoId
        ) {
          router.push("/escolha-divisao");
        } else {
          if (store.getters.divisaoId) {
            DivisaoService.find(store.getters.divisaoId)
              .then((res) => {
                divisao.value = res.data;
              })
              .catch((err) => {
                console.log(err);
                showNotify({
                  type: "negative",
                  message: "Erro ao buscar divisão",
                });
              })
              .finally(() => (loading.value = false));
          }
        }
      }
    };

    const logout = (): void => {
      loading.value = true;
      store
        .dispatch("logout")
        .then(() => router.push("/login"))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const removeDivisao = (): void => {
      loading.value = true;
      store
        .dispatch("remove_divisao")
        .then(() => router.push("/escolha-divisao"))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const voltar = (): void => {
      router.go(-1);
    };

    const leave = (): void => {
      BaseService.list("impersonate/leave")
        .then((response) => {
          localStorage.setItem("token", response.data.token); // Salva o novo token
          localStorage.setItem("perfil", "administrador");
          store
            .dispatch("isSimulation", false)
            .then()
            .catch((err) => {
              console.log(err);
              showNotify({
                type: "negative",
                message: "Erro ao fazer simulação",
              });
            })
            .finally(() => {
              showNotify({
                type: "positive",
                message: `Simulação finalizada com sucesso`,
              });
              window.location.reload();
              loading.value = false;
            });
        })
        .catch((err) => {
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    onMounted(() => {
      getUser();
    });

    const isSimulation = computed(() => {
      return store.getters.isSimulation;
    });
    return {
      leftDrawerOpen: ref(false),
      loading,
      urlImg,
      user,
      divisao,
      getUser,
      getDivisao,
      removeDivisao,
      controleAcesso,
      store,
      logout,
      route,
      voltar,
      leave,
      isSimulation,
    };
  },
});
