
import Disciplina from "@/interfaces/Disciplina";
import User from "@/interfaces/User";
import BaseService from "@/services/admin/BaseService";
import { defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const temRedacao = ref<boolean>();
    const disciplinas = ref<Disciplina[]>();

    const getDisciplinas = (): void => {
      BaseService.list("professor/minhas-disciplinas")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      getDisciplinas();
      temRedacao.value =
        props.user.disciplinas.filter(
          (d) => d.nome.includes("reda") || d.nome.includes("por")
        ).length > 0;
    });

    return { temRedacao, disciplinas };
  },
});
